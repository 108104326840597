import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "../components/background-image"
import officeImage from '../images/office.jpg';

import aboutStyles from "../styles/about.module.scss"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <BackgroundImage
        color="rgba(0, 178, 255, 0.69)"
        image={officeImage}
        backgroundPosition="0% 35%"
        title="Meet Our Team"
      />
    <div className={aboutStyles.greyBackground}>
      <div className="container">
        <h1>Our Story</h1>
        <p>
          For over 25 years, we've been serving the Palm Harbor, Oldsmar,
          Clearwater, St. Pete, Tampa community. We attribute our success to a
          personalized approach to taxes and business, where a unique and
          creative strategy is designed for each client. With over 2,500
          satisfied clients in all walks of life, we encourage you to stop
          searching and let us transform your taxes or business into a{" "}
          <span className={aboutStyles.blue}>Work of Art</span>!
        </p>
        <p>
          Our services encompass nearly every aspect of your financial life; if
          we were doctors, we would call ourselves{" "}
          <span className={aboutStyles.blue}>
            General Practitioners Specializing in Tax Surgery
          </span>
          . We emphasize that most of your hard-earned dollars stay in your
          pocket. With clients spread across the US, Canada, Europe, Latin
          America, and Asia, we are experienced in many matters of accounting
          and taxation including IRS problem resolution, estates and trusts,
          business formation, financial planning and investment, real estate,
          and business sales. For a more complete list, check out our services.
        </p>
        <p>
          Please call us today at{" "}
          <a href="tel:7277875290">
          (727) 787-5290</a>. We'll be
          happy to schedule an initial consultation!
        </p>
      </div>
    </div>
    <div className={aboutStyles.whiteBackground}>
      <div className="container">
        <h1>The Tax Artists</h1>
        <div className={aboutStyles.gridContainer}>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/rob.jpg")}
              alt="robert dimarco"
            />
            <div>
              <h3>Robert&nbsp;DiMarco, CPA,&nbsp;MST</h3>
              <h5>Managing Director</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/jacqui.jpg")}
              alt="jacquelyn dimarco"
            />
            <div>
              <h3>Jacquelyn&nbsp;DiMarco</h3>
              <h5>Chief&nbsp;Operating&nbsp;Officer</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/amy.jpg")}
              alt="amy risavy"
            />
            <div>
              <h3>Amy&nbsp;Risavy, CPA</h3>
              <h5>Tax Manager</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/cristi.jpg")}
              alt="cristi cola fleming"
            />
            <div>
              <h3>Cristi&nbsp;Cola&nbsp;Fleming, MBA</h3>
              <h5>Senior&nbsp;Tax&nbsp;Associate</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/lisa.jpg")}
              alt="lisa lu"
            />
            <div>
              <h3>Xiuli&nbsp;"Lisa"&nbsp;Lu, CPA, EA</h3>
              <h5>Senior&nbsp;Tax&nbsp;Associate</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/mike.jpg")}
              alt="mike lee"
            />
            <div>
              <h3>Mike Lee, MAcc</h3>
              <h5>Senior Tax Associate</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/linda.jpg")}
              alt="linda halket"
            />
            <div>
              <h3>Linda Halket</h3>
              <h5>QuickBooks&nbsp;Specialist</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/eric.jpg")}
              alt="eric foster"
            />
            <div>
              <h3>Eric Foster</h3>
              <h5>Administrative&nbsp;Tax&nbsp;Associate</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/kelly copy.jpg")}
              alt="kelly morgan"
            />
            <div>
              <h3>Kelly Morgan</h3>
              <h5>Administrative&nbsp;Tax&nbsp;Associate</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
          <div className={aboutStyles.imageBox}>
            <img
              src={require("../images/taxpictures/melissa copy.jpg")}
              alt="melissa gonzalez"
            />
            <div>
              <h3>Melissa Gonzalez</h3>
              <h5>Tax&nbsp;Admin&nbsp;Office&nbsp;Manager</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                modi doloremque amet earum magni? At eius perspiciatis saepe
                debitis itaque numquam delectus nulla repudiandae
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
